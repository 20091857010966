import { Button } from "reactstrap";
import styles from "./index.module.scss";
import { getDealer } from "@services/Dealers/dealers.service";
import CitasActionsVenta from "@views/LeadVenta/CitasActionsVenta/CitasActionsVenta";
import ModalMMA from "@views/LeadVenta/ModalMMA/ModalMMA";
import ModalReservar from "@views/LeadVenta/ModalReservar/ModalReservar";
import SellContractModal from "@views/LeadVenta/SellContractModal/SellContractModal";
import ModalHistoricoLead from "@views/LeadVenta/ModalHistoricoLead/ModalHistoricoLead";
import EditClient from "@views/LeadVenta/ModalEditClient/ModalClient";
import ModalMensajesLead from "@views/LeadVenta/ModalMensajesLead/ModalMensajesLead";
import ModalMail from "@views/LeadVenta/ModalMail/ModalMail";
import ModalProposal from "@views/ModalProposal/ModalProposal";
import ModalCancelReservation from "@views/LeadVenta/ModalCancelReservation/ModalCancelReservation";
import ModalFinancial from "@views/LeadVenta/ModalFinancial";
import { get, post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import ModalChangeState from "@views/ModalChangeState/ModalChangeState";
import { UserContext } from "@contexts/UserContext";
import { getCommercialProposal, deleteCommercialProposal, postCommercialProposal } from "@services/CommercialProposalService/commercialProposal";
import { FiEye, FiTrash2 } from "react-icons/fi";
import { Alert } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import CommentsWithLink from "@components/CommentsWithLink";

import ejsTemplateRender from "@helpers/ejsTemplateRender";


import {
  MdPersonPin,
  MdStar,
  MdDirectionsCarFilled,
  MdOutlinePeopleAlt,
  MdInsertComment,
  MdOutgoingMail,
  MdEuroSymbol
} from "react-icons/md";
import moment from "moment";
import { errorToast, successToast } from "@helpers/toastFunctions";
import { orderByDate } from "@helpers/orderByDate";
import OtherLeadsList from "@views/LeadVenta/FormLead/OtherLeadsList/OtherLeadsList";
import PaginationOtherLeads from "@components/Pagination/Pagination"
import { useTranslation } from 'react-i18next';
import Locale from "@constants/Locale";
import LEAD_STATUS from "@constants/LeadStatus";
import instance from "@services/base";
import Select from "@components/Select";
import { getFilterSellAgent, getFilterSellLeadStateActions } from "@services/Filtros/filtros";
import ReservationTypes from "@constants/ReservationTypes";

const FormLead = ({ id_lead, id_cliente }) => {
  document.title = "Flexicar | Lead de Venta";
  const [client, setClient] = useState(null);
  const [reservation, setReservation] = useState(null);
  const [lead, setLead] = useState(null);
  const [leadComment, setLeadComment] = useState({ userComment: "", deliverVehicleComment: "" });
  const [otherLeads, setOtherLeads] = useState([]);
  const [modalReserva, setModalReserva] = useState(false);
  const [modalHistorico, setModalHistorico] = useState(false);
  const [modalMensajes, setModalMensajes] = useState(false);
  const [modalMail, setModalMail] = useState(false);
  const [modalEditClient, setModalEditClient] = useState(false);
  const [modalMMA, setModalMMA] = useState(false);
  const [modalContrato, setModalContrato] = useState(false);
  const [modalCancelation, setModalCancelation] = useState(false);
  const [modalProposal, setModalProposal] = useState(false);
  const [modalFinancial, setModalFinancial] = useState(false);
  const [idContract, setIdContract] = useState(null);
  const [localInfo, setLocalInfo] = useState(null);
  const [localId, setLocalId] = useState(null);
  const [cita, setCita] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const { user } = useContext(UserContext);
  const { phone } = useContext(UserContext);
  const { name } = useContext(UserContext);
  const { email } = useContext(UserContext);
  const [modalChangeState, setModalChangeState] = useState(false);
  const [leadSelected, setLeadSelectedModal] = useState(null);
  const [clientSelectedModal, setClientSelectedModal] = useState(null);
  const [statePagination, setStatePagination] = useState(0);
  const mmaStatusLead = [
    LEAD_STATUS.ganado,
    LEAD_STATUS.reservado_pago_transferencia,
    LEAD_STATUS.reservado_pendiente_pago_pasarela,
    LEAD_STATUS.reservado_pago_tienda,
    LEAD_STATUS.pagado_tarjeta_pasarela,
    LEAD_STATUS.reserva_online
  ];
  const disabledStatus = [
    LEAD_STATUS.reservado_pago_tienda,
    LEAD_STATUS.reservado_pago_transferencia,
    LEAD_STATUS.pagado_bizum_pasarela,
    LEAD_STATUS.pagado_tarjeta_pasarela,
    LEAD_STATUS.pagado_tienda,
    LEAD_STATUS.reserva_online
  ];
  const ganado = LEAD_STATUS.ganado;
  const itemsPerPage = 10;
  const { i18n, t } = useTranslation('formLead');



  const [commercialProposalId, setCommercialProposalId] = useState(null);
  const [commercialProposal, setCommercialProposal] = useState(null);
  const [expiredBannerCommercialProposal, setExpiredBannerCommercialProposal] = useState(null);


  const setModal = () => {
    setModalReserva(!modalReserva);
  };

  const launchModalHistorico = () => {
    setModalHistorico(!modalHistorico);
  };

  const launchModalMensajes = () => {
    setModalMensajes(!modalMensajes);
  };

  const launchModalMail = () => {
    setModalMail(!modalMail);
  };


  const launchModalMMA = () => {
    setModalMMA(!modalMMA);
  };

  const launchModalContrato = () => {
    setModalContrato(!modalContrato);
  };

  const launchModalCancelation = () => {
    setModalCancelation(!modalCancelation);
  };


  const launchModalChangeState = (leadSelected, clientSelected) => {
    setLeadSelectedModal(leadSelected);
    setClientSelectedModal(clientSelected);
    setModalChangeState(!modalChangeState);
  };


  const launchModalProposal = () => {
    setModalProposal(!modalProposal);
  };

  const launchModalFinancial = () => {
    setModalFinancial(!modalFinancial);
  };


  const portugueseAccessProtection = i18n.language === Locale.ES


  const getClientData = async (id) => {
    const response = await get(API.GET_CLIENT(id))
    setClient(response)

  };

  const getReservation = async (id_client, id_lead) => {
    const response = await post(API.GET_RESERVA, {
      id_client,
      id_lead,
    });
    if (response && response[0]) {
      setReservation(response[0]);
    }
  };

  const getContract = async (id_client, id_lead) => {
    const response = await post(API.GET_CONTRACT_LEAD, {
      id_client,
      id_lead,
    });
    if (response && response[0]) {
      setIdContract(response[0].id);
    }
  };

  const getLeadData = useCallback(
    async (id) => {
      const response = await post(API.GET_LEAD_VENTA, {
        agent: user,
        id,
      });
      setLead(response[0]);

      const comment = response[0].comentario;
      if (comment) {
        const commentSplitted = comment.split("\n");
        setLeadComment({
          userComment: commentSplitted[0],
          deliverVehicleComment: commentSplitted[1]
        });
      }

      setCita(response[0].fecha_cita);
      setLocalId(response[0].id_local_for_marketplace);
      setCommercialProposalId(response[0].commercial_proposal)
    },
    [user]
  );

  useEffect(() => {
    if (commercialProposalId) {
      const queryCommercialProposal = async () => {
        const { data } = await getCommercialProposal(commercialProposalId)
        setCommercialProposal(data)
      }
      queryCommercialProposal()
    }
  }, [commercialProposalId])

  const getOtherClientLeads = useCallback(async (id_client, id_lead) => {
    const response = await post(API.GET_OTHER_CLIENT_LEADS, {
      id_client,
    });
    setOtherLeads(orderByDate(response[0]));
  }, []);

  const filteredOtherLeads = () => {
    return otherLeads.slice(statePagination, statePagination + itemsPerPage);
  };

  const launchDestacado = async () => {
    let d = lead.lead_destacado;
    if (d === null) {
      const response = await post(API.CREATE_DESTACADO, {
        user,
        lead: id_lead,
      });
      if (response.error) {
        return errorToast(<span>{t("errors.errorFeatured")}</span>);
      } else {
        setLead({ ...lead, lead_destacado: 1 });
      }
    } else if (d === 1 || d === 0) {
      d = d === 0 ? 1 : 0;
      const response = await post(API.UPDATE_DESTACADO, {
        user,
        lead: id_lead,
        status: d,
      });
      if (response.error) {
        return errorToast(<span>{t("errors.errorUpdateFeatured")}</span>);
      } else {
        setLead({ ...lead, lead_destacado: d });
      }
    }
  };

  useEffect(() => {
    getClientData(id_cliente);
    getLeadData(id_lead);
    getOtherClientLeads(id_cliente);
  }, [getLeadData, getOtherClientLeads, id_cliente, id_lead]);

  useEffect(() => {
    if (id_cliente && id_lead && !reservation) {
      getReservation(id_cliente, id_lead);
      getContract(id_cliente, id_lead);
    }
  }, [id_cliente, id_lead, reservation]);

  const updateLeadState = async (opt) => {
    if (opt && lead && opt.value != lead.id_estado_lead) {
      const response = await post(API.UPDATE_LEAD_VENTA_STATUS, {
        label: opt.label,
        value: opt.value,
        lead: id_lead,
        client: id_cliente,
        user
      });
      if (response.changedRows && response.changedRows === 1) {
        setLead({
          ...lead,
          nombre_estado_lead: opt.label,
          id_estado_lead: opt.value,
        });
        successToast(<span>{t("success.successUpdateState")}</span>);
      } else {
        errorToast(<span>{t("errors.errorUpdateState")}</span>);
      }
    }
  }

  const formatFullName = (client) => {
		if (!client?.firstSurname) {
			return client.name;
		}

		if (!client?.secondSurname) {
			return `${client.name} ${client.firstSurname}`;
		}

		return `${client.name} ${client.firstSurname} ${client.secondSurname}`;
	};

  const send_email = async () => {
    let data = {
      "nombreApellidoCliente": formatFullName(client),
      "nombreApellidosEncargado": localInfo?.encargado?.label,
      "nombreApellidosAgente": lead?.nombre_agente,
      "fecha": moment(cita).format('DD/MM/YYYY'),
      "hora": moment(cita).format("HH:mm"),
      "concesionario": lead?.nombre_sede_v,
      "telefono_centro": localInfo?.telefono,
      "direccion": localInfo?.direccion,
      "url_concesionario": localInfo?.mapa,
      "marca": lead?.nombre_marca_v,
      "modelo": lead?.nombre_modelo_v,
      "version": lead?.nombre_version_v,
      "anho": lead?.anio,
      "carroceria": lead?.carroceria,
      "transmision": lead?.transmision,
      "combustible": lead?.combustible,
      "km": lead?.kilometros,
      "precio_contado": lead?.pvp_contado,
      "precio_credito": lead?.pvp_financiado,
      "url_vehiculo_web_flexicar": lead?.URL
    }

    try {
      await post(API.SEND_APPOINTMENT_SALES_INTERNAL, {
        data: {
          ...data,
          email_cliente: client?.contact?.email,
          email: `ventas@flexicar.es,${email},${localInfo?.email}`,
          telefono_cliente: client?.contact?.primaryPhone,
          template: 4
        }
      });

      await post(API.SEND_APPOINTMENT_SALES_EXTERNAL, {
        data: { ...data, email: client?.contact?.email, telefono_cliente: client?.contact?.primaryPhone, template: 3 }
      });
    } catch {
      return errorToast(<span>{t("errors.errorSendEmail")}</span>);
    }
  };
  const newAppointment = async () => {
    if (cita === "" || cita === null) {
      return errorToast(<span>{t("errors.errorDate")}</span>);
    }
    if (moment(cita).format("HH") === "00") {
      return errorToast(<span>{t("errors.errorHour")}</span>);
    }

    try {
      setSpinner(true);
      let response = await post(API.NEW_APPOINTMENT_VENTA, {
        lead_id: id_lead,
        client_id: id_cliente,
        cita,
        user,
        status_lead_id: 12,
        status_lead: "Citado"
      });
      if (response) {
        setLead({
          ...lead,
          id_estado_lead: 12,
          nombre_estado_lead: "Citado",
        });
        if (i18n.language === Locale.ES) {
          await send_email();
        }
        if (lead.lead_destacado !== 1) {
          await launchDestacado();
        }
        successToast(<span>{t("success.successCreated")}</span>);
        return setSpinner(false);
      }
    } catch {
      setCita(null);
      setSpinner(false);

      return errorToast(<span>{t("errors.errorCreateAppointment")}</span>);
    }
  };

  const getLocalInfo = useCallback(async (localId) => {
    try {
      const dealer = await getDealer(`/dealers`, localId);
      if (dealer) {
        setLocalInfo(dealer);
      }
    } catch (e) {
      errorToast(<span>{t("errors.errorLoadDealer")}</span>);
    }
  }, [t]);

  useEffect(() => {
    if (localId) {
      getLocalInfo(localId);
    }
  }, [getLocalInfo, localId]);

  const deleteAppointment = async () => {
    try {
      let response = await post(API.DELETE_APPOINTMENT_VENTA, {
        lead_id: id_lead,
        client_id: id_cliente,
        status_name: "Dudoso",
        status_id: 3
      });
      if (response) {
        setCita("");
        setLead({ ...lead, nombre_estado_lead: "Dudoso", id_estado_lead: 3 });
        successToast(<span>{t("success.successDeleted")}</span>);
      } else {
        errorToast(<span>{t("errors.errorDeleteAppointment")}</span>);
      }
    } catch (e) {
      errorToast(<span>{t("errors.errorDeleteAppointment")}</span>);
    }
  };


  const changeAgent = async (opt) => {
    try {
      const response = await instance.put(`${API.CHANGE_AGENT_VENTAS}/${id_lead}/agent/`, {
        id: opt.value
      });
      if (response.status === 204) {
        setLead({
          ...lead,
          nombre_agente: opt.label,
          id_agente: opt.value
        });
        successToast(t("success.successUpdatedManager"));
      }
    } catch (error) {
      errorToast(<span>{t("errors.errorUpdateManager")}</span>);
    }
  };

  const watchCommercialProposal = () => {
    if (commercialProposal.isExpire) {
      setExpiredBannerCommercialProposal(true)
    } else {
      gethtmltopdf()
    }
  }

  const createCommercialProposal = async (postData) => {
    try {
      const { data } = await postCommercialProposal(postData);
      if (lead.lead_destacado !== 1) {
        await launchDestacado();
      }

      setCommercialProposalId(data.id)
      successToast(
        `${t("success.successValidProposal")} ${moment()
          .add(7, "days")
          .format("YYYY-MM-DD")}`
      );
      return { data }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        errorToast(<span>{t("errors.errorCreateProposal")}</span>);
      } else {
        errorToast(<span>{t("errors.errorCreateProposal2")}</span>);
      }
    }
  };

  const dCommercialProposal = async () => {
    await deleteCommercialProposal(commercialProposalId)
    setCommercialProposalId(null)
    setCommercialProposal(null)
    if (lead.lead_destacado === 1) {
      await launchDestacado();
    }
    successToast(<span>{t("success.successDeletedProposal")}</span>);
  }


  let template;
  const reset = () =>
    (template = document.getElementById("template").innerHTML = <div></div>);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => reset(),
  });

  const gethtmltopdf = async () => {
    try {

      const dataTemplate = { commercialProposal, localInfo };

      template = await ejsTemplateRender(3, dataTemplate);

      handlePrint();
    } catch (err) {
      errorToast(<span>{t("errors.errorPrintVehicle")}</span>);
    }
  };

  return (
    <>
      <div style={{ display: "none" }}>
        <div ref={componentRef} className="m-5" id="template">
          {template}
        </div>
      </div>
      <div>
        <div
          className="clearfix mb-3"
          style={{
            padding: ".7rem",
            backgroundColor: "#2C2C2C",
            display: "flex",
            alignItems: "center",
            marginTop: "1rem",
            justifyContent: "space-between",
          }}
        >
          <span className="tittle ml-4 mr-auto">{t("personalInformation")}</span>
          <>
            {client && (
              <EditClient
                leadType="sell-leads"
                isOpen={modalEditClient}
                handleIsOpen={setModalEditClient}
                client={client}
                leadId={id_lead}
                clientId={id_cliente}
                getDataclient={getClientData}
                isWebClient={client?.isWebClient}
              />
            )}
          </>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-12">
            <p className={styles.itemList}>
              <b>{t("name")}</b>&nbsp;&nbsp;&nbsp;{client?.name}
            </p>
            <p className={styles.itemList}>
              <b>{t("mainPhone")}</b>&nbsp;&nbsp;&nbsp;
              {client?.contact?.primaryPhone && (
                <tcxspan
                  tcxhref={client?.contact.primaryPhone}
                  title={`Call ${client?.contact.primaryPhone} via 3CX`}
                >
                  {client?.contact.primaryPhone}
                </tcxspan>
              )}
            </p>
            <p className={styles.itemList}>
              <b>{t("email")}</b>&nbsp;&nbsp;&nbsp;{client?.contact.email}
            </p>
            <p className={styles.itemList}>
              <b>{t("registerDate")}</b>&nbsp;&nbsp;&nbsp;
              {moment(client?.createdAt).format("DD/MM/YYYY  HH:mm")}
            </p>
            <p className={styles.itemList}>
              <b>{t("province")}</b>&nbsp;&nbsp;&nbsp;{client?.address?.province?.name}
            </p>
            <p className={styles.itemList}>
              <b>{t("receiveAdvertising")}</b>&nbsp;&nbsp;&nbsp;{client?.unsubcribed ? "SI" : "No"}
            </p>
          </div>
          <div className="col-md-6 col-sm-12">
            <p className={styles.itemList}>
              <b>{t("surnames")}</b>&nbsp;&nbsp;&nbsp;{`${client?.firstSurname || ""} ${client?.secondSurname || ""}`}
            </p>
            <p className={styles.itemList}>
              <b>{t("secondaryPhone")}</b>&nbsp;&nbsp;&nbsp;
              {client?.telefono2 && (
                <tcxspan
                  tcxhref={client?.telefono2}
                  title={`Call ${client?.telefono2} via 3CX`}
                >
                  {client?.telefono2}
                </tcxspan>
              )}
            </p>
            <p className={styles.itemList}>
              <b>{t("lastInterest")}</b>&nbsp;&nbsp;&nbsp;
              {client?.f_ultima_consolidacion &&
                moment(client.f_ultima_consolidacion).format(
                  "DD/MM/YYYY  HH:mm"
                )}
            </p>
            <p className={styles.itemList}>
              <b>{t("lastMod")}</b>&nbsp;&nbsp;&nbsp;
              {moment(client?.updatedAt).format(
                "DD/MM/YYYY  HH:mm"
              )}
            </p>
            <p className={styles.itemList}>
              <b>{t("population")}</b>&nbsp;&nbsp;&nbsp;{client?.address?.municipality}
            </p>
            <p className={styles.itemList}>
              <b>{t("previousLead")}</b>&nbsp;&nbsp;&nbsp;
              {otherLeads.length > 1 ? "Si" : "No"}
            </p>
          </div>
        </div>
      </div>
      <div
        className="clearfix mb-3"
        style={{
          padding: ".7rem",
          backgroundColor: "#2C2C2C",
          display: "flex",
          alignItems: "center",
          marginTop: "1rem",
          justifyContent: "space-between",
        }}
      >
        <span className="tittle ml-4 mr-auto">
          Lead &nbsp;&nbsp;
          <MdStar
            color={lead?.lead_destacado ? "#007bff" : "grey"}
            size={20}
            role="button"
            onClick={() => launchDestacado()}
          />
        </span>
        <>
          {i18n.language === Locale.ES &&
            <MdOutgoingMail
              color="white"
              size={20}
              role="button"
              onClick={() => launchModalMail()}
            />}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <MdOutlinePeopleAlt
            color="white"
            size={20}
            role="button"
            onClick={() => launchModalHistorico()}
          />{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <MdInsertComment
            color="white"
            size={20}
            role="button"
            onClick={() => launchModalMensajes()}
          />{" "}
          &nbsp;&nbsp;
        </>
        <ModalMail
          launchModal={launchModalMail}
          status={modalMail}
          vehicle_marca={lead?.nombre_marca_v}
          email={client?.contact?.email}
          vehicle_modelo={lead?.nombre_modelo_v}
          vehicle_url={lead?.URL}
          agent_name={name}
          agent_phone={phone}
        />
        <ModalHistoricoLead
          lead_id={id_lead}
          setModal={launchModalHistorico}
          status={modalHistorico}
        />
        <ModalMensajesLead
          lead_id={id_lead}
          vehicle_id={lead?.vehicle_id}
          client_id={id_cliente}
          user_id={user}
          setModal={launchModalMensajes}
          status={modalMensajes}
        />

        {modalProposal && localInfo && <ModalProposal
          setModal={launchModalProposal}
          status={modalProposal}
          client={commercialProposal ? null : client}
          lead={commercialProposal ? { id_lead: lead.id_lead, id_agente: lead.id_agente } : lead}
          proposalData={commercialProposal}
          createCommercialProposal={createCommercialProposal}
          localInfo={localInfo}
        />}

        <ModalFinancial
          setModal={launchModalFinancial}
          status={modalFinancial}
        />

      </div>
      <div className="row">
        <div className="col-md-4 col-sm-12">
          <div
            className="clearfix mb-3"
            style={{
              padding: ".3rem",
              backgroundColor: "#e7e7e7",
              display: "flex",
              alignItems: "center",
              marginTop: "1rem",
            }}
          >
            <span className="tittle_sub ml-4"> {t("image")}</span>
          </div>
          <img
            style={{ width: "100%" }}
            src={lead && lead.image_url}
            alt={lead?.id}
          />
          {portugueseAccessProtection && mmaStatusLead.includes(lead?.id_estado_lead) && (
            <Button
              style={{ width: "100%" }}
              className="btn btn-light btn-outline-dark btn-flexicar-red mt-2"
              onClick={() => launchModalMMA(true)}
            >
              Mutua Madrileña
            </Button>
          )}

          {portugueseAccessProtection && lead?.id_version_gvo !== -1 &&
            (!commercialProposalId ||
              (!commercialProposalId && commercialProposal?.isExpire)) && (
              <Button
                className="w-100 btn btn-flexicar mt-2"
                onClick={() => launchModalProposal(true)}
              >
                {t("createCommProposal")}
                {/* //TO DO: Once created change state */}
              </Button>
            )}

          {
            portugueseAccessProtection && commercialProposalId && (
              <div className="d-flex mt-2">
                <Button

                  className="flex-fill orange-btn font-weight-bold bg-transparent p-2 mr-2"
                  onClick={() => watchCommercialProposal()}

                >
                  <FiEye className="pr-1 mx-auto" size={22} />
                  {expiredBannerCommercialProposal ? "Propuesta caducada" : "Ver Propuesta comercial"}
                </Button>
                <Button
                  className="orange-btn bg-transparent"
                  onClick={() => dCommercialProposal()}
                >
                  <FiTrash2 size={18} className="mx-auto" />
                </Button>
              </div>
            )
          }

          {
            expiredBannerCommercialProposal && commercialProposalId &&
            <Alert className="mt-3" key='secondary' variant='secondary'>
              {t("proposalInfo")}
            </Alert>
          }

          {portugueseAccessProtection && reservation && (
            <Button
              className="w-100 btn btn-flexicar-red mt-2"
              onClick={() => launchModalCancelation(true)}
            >
              {t("cancel")}
            </Button>
          )}

          {portugueseAccessProtection && !reservation && (
            <Button
              className="w-100 btn btn-flexicar-red mt-2"
              onClick={() => setModal(true)}
            >
              {t("reserve")}
            </Button>
          )}

          {lead && ((lead.id_estado_lead === ganado && portugueseAccessProtection) || reservation) && lead.stock_externo !== 1 && (
            <Button
              className="w-100 btn btn-flexicar-red mt-2"
              onClick={() => {
                idContract
                  ? window.open("/main/docu/" + idContract, "_blank")
                  : launchModalContrato(true);
              }}
            >
              {idContract ? "Ver Contrato" : "Crear Contrato"}
            </Button>
          )}

          <ModalMMA
            lead_id
            client_id
            lead={lead}
            client={client}
            setModal={setModalMMA}
            status={modalMMA}
          />
          <ModalReservar
            client={client}
            lead={lead}
            setModal={setModal}
            status={modalReserva}
          />
          <ModalCancelReservation
            reservation={reservation}
            setModal={setModalCancelation}
            status={modalCancelation}
          />
          {lead && modalContrato && (
            <SellContractModal
              isOpen={modalContrato}
              setIsOpen={setModalContrato}
              mode="create"
              lead={lead}
              client={client}
              reservation={reservation}
              setIdContract={setIdContract}
            />
          )}
          <div
            className="clearfix mb-3"
            style={{
              padding: ".3rem",
              backgroundColor: "#e7e7e7",
              display: "flex",
              alignItems: "center",
              marginTop: "1rem",
            }}
          >
            <span className="tittle_sub ml-4">{t("clientInfo")}</span>
          </div>
          {lead && lead.comentario && (
            <div>
              <div className={styles.userComment}>
                <MdPersonPin
                  color="black"
                  size={30}
                />
                <CommentsWithLink text={leadComment.userComment} className="comment" />
              </div>
              {leadComment.deliverVehicleComment && (
                <CommentsWithLink text={leadComment.deliverVehicleComment} className="comment" />
              )}
            </div>
          )}
          {reservation && reservation.origin === ReservationTypes.WEB && (
              <div className={styles.userComment}>
                <MdPersonPin
                  color="black"
                  size={30}
                />
                <span className={styles.comment}>{t("clientWebBooking")} {reservation.importe}€</span>
              </div>
          )}
          {(!reservation || reservation?.origin !== ReservationTypes.WEB) && (lead && !lead.comentario) && (
            <span>
              {t("noCommentClient")}
            </span>
          )}
        </div>
        <div className="col-md-4 col-sm-12">
          <div
            className="clearfix mb-3"
            style={{
              padding: ".3rem",
              backgroundColor: "#e7e7e7",
              display: "flex",
              alignItems: "center",
              marginTop: "1rem",
              justifyContent: "space-between",
            }}
          >
            <span className="tittle_sub ml-4"> {t("vehicleData")}</span>
            <MdDirectionsCarFilled
              color="#007bff"
              size={20}
              role="button"
              onClick={() => window.open(`/main/vehicle/${lead?.vehicle_id}?tab=datos`)}
            />
          </div>
          <div className="d-flex flex-row align-items-center">
            <div className="col-md-11 col-sm-12">
              <p className={styles.itemList}>
                <b>{t("stockDays")}</b>&nbsp;&nbsp;&nbsp;{lead && lead.dias_desde}
              </p>
              <p className={styles.itemList}>
                <b>{t("lastMod")}</b>&nbsp;&nbsp;&nbsp;
                {lead && lead.ultima_actualizacion} días
              </p>
              <p className={styles.itemList}>
                <b>{t("companyStock")}</b>&nbsp;&nbsp;&nbsp;{lead && lead.v_stock}
              </p>
              <p className={styles.itemList}>
                <b>{t("licensePlate")}</b>&nbsp;&nbsp;&nbsp;{lead && lead.matricula}
              </p>
              <p className={styles.itemList}>
                <b>{t("brand")}</b>&nbsp;&nbsp;&nbsp;{lead && lead.nombre_marca_v}
              </p>
              <p className={styles.itemList}>
                <b>{t("model")}</b>&nbsp;&nbsp;&nbsp;{lead && lead.nombre_modelo_v}
              </p>
              <p className={styles.itemList}>
                <b>{t("version")}</b>&nbsp;&nbsp;&nbsp;{lead && lead.nombre_version_v}
              </p>
              <p className={styles.itemList}>
                <b>{t("price")}</b>&nbsp;&nbsp;&nbsp;{lead && lead.pvp_v}
              </p>
              <p className={styles.itemList}>
                <b>{t("cashPrice")}</b>&nbsp;&nbsp;&nbsp;{lead && lead.pvp_contado_v}
              </p>
              <p className={styles.itemList}>
                <b>{t("headQuarters")}</b>&nbsp;&nbsp;&nbsp;{lead && lead.nombre_sede_v}
              </p>
              <p className={styles.itemList}>
                <b>{t("body")}</b>&nbsp;&nbsp;&nbsp;{lead && lead.carroceria}
              </p>
              <p className={styles.itemList}>
                <b>{t("year")}</b>&nbsp;&nbsp;&nbsp;{lead && lead.anio_mes}
              </p>
              <p className={styles.itemList}>
                <b>{t("kms")}</b>&nbsp;&nbsp;&nbsp;{lead && lead.kilometros}
              </p>
              <p className={styles.itemList}>
                <b>{t("transmission")}</b>&nbsp;&nbsp;&nbsp;{lead && lead.transmision}
              </p>
              <p className={styles.itemList}>
                <b>{t("fuel")}</b>&nbsp;&nbsp;&nbsp;{lead && lead.combustible}
              </p>
              <p className={styles.itemList}>
                <b>{t("condition")}</b>&nbsp;&nbsp;&nbsp;{lead && lead.nombre_estado}
              </p>
            </div>
            {
              portugueseAccessProtection &&
              <div className="col-md-1 col-sm-12">
                <MdEuroSymbol
                  color="#007bff"
                  size={20}
                  role="button"
                  onClick={() => launchModalFinancial()}
                />{" "}
              </div>
            }
          </div>
        </div>
        <div className="col-md-4 col-sm-12">
          <div
            className="clearfix mb-3"
            style={{
              padding: ".3rem",
              backgroundColor: "#e7e7e7",
              display: "flex",
              alignItems: "center",
              marginTop: "1rem",
            }}
          >
            <span className="tittle_sub ml-4">{t("leadActions")}</span>
          </div>
          <Select
            placeholder="Estado actual"
            loadOptions={getFilterSellLeadStateActions}
            value={{
              label: lead?.nombre_estado_lead,
              value: lead?.id_estado_lead
            }}
            onChange={updateLeadState}
            isDisabled={!lead || disabledStatus.includes(lead.id_estado_lead)}
          />
          <br />
          <CitasActionsVenta
            className="btn btn-light btn-outline-dark bgroup btn-flexicar"
            cita={cita}
            agente_lead={lead && lead.id_agente}
            client_phone={client && client.contact?.primaryPhone}
            setCita={setCita}
            spinner={spinner}
            newAppointment={newAppointment}
            deleteAppointment={deleteAppointment}
            client_id={id_cliente}
            lead_id={lead && lead.id_lead}
          />
          <ModalChangeState
            status={modalChangeState}
            setModal={launchModalChangeState}
            id_lead={leadSelected}
            id_cliente={clientSelectedModal}
          ></ModalChangeState>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <div
            className="clearfix mb-3"
            style={{
              padding: ".3rem",
              backgroundColor: "#e7e7e7",
              display: "flex",
              alignItems: "center",
              marginTop: "1rem",
            }}
          >
            <span className="tittle_sub ml-4">{t("leadInformation")}</span>
          </div>
        </div>
        <div className="col-md-6 col-sm-12 pl-5">
          <p className={styles.itemList}>
            <b>{t("acceptT")}</b>&nbsp;&nbsp;&nbsp;{lead && lead.tyc_v}
          </p>
          <p className={styles.itemList}>
            <b>{t("receiveInfo")}</b>&nbsp;&nbsp;&nbsp;{lead && lead.info_v}
          </p>
          <p className={styles.itemList}>
            <b>{t("originator")}</b>&nbsp;&nbsp;&nbsp;{lead && lead.nombre_originador}
          </p>
        </div>
        <div className="col-md-6 col-sm-12 pl-5">
          <p className={styles.itemList}>
            <b>{t("origin")}</b>&nbsp;&nbsp;&nbsp;
            {lead && lead.nombre_procedencia}
          </p>
          <p className={styles.itemList}>
            <b>{t("contact")}</b>&nbsp;&nbsp;&nbsp;
            {lead && lead.nombre_tipo_contacto}
          </p>

          <div className="d-flex align-items-center w-100">
            <b>{t("agent")}</b>&nbsp;&nbsp;&nbsp;
            <Select
              placeholder={lead?.nombre_agente}
              loadOptions={getFilterSellAgent}
              value={{
                label: lead?.nombre_agente,
                value: lead?.id_agente
              }}
              onChange={changeAgent}
            />
          </div>
        </div>
      </div>
      <div
        className="clearfix mb-3"
        style={{
          padding: ".7rem",
          backgroundColor: "#2C2C2C",
          display: "flex",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <span className="tittle ml-4">{t("othersLeads")}</span>
      </div>
      {otherLeads && otherLeads.length !== 0 ? (
        <>
          <table className="table table-striped fl-table">
            <thead>
              <tr>
                <th scope="col-md-1">{t("tableHeader.creationDate")}</th>
                <th scope="col-md-1">{t("tableHeader.lastMod")}</th>
                <th scope="col-md-1">{t("tableHeader.condition")}</th>
                <th scope="col-md-1">{t("tableHeader.origin")}</th>
                <th scope="col-md-1">{t("tableHeader.vehicle")}</th>
                <th scope="col-md-1">{t("tableHeader.price")}</th>
                <th scope="col-md-1">{t("tableHeader.actions")}</th>
              </tr>
            </thead>
            <tbody>
              {filteredOtherLeads().map((itm) => (
                <tr key={itm.id} style={{ fontSize: "15px" }}>
                  <OtherLeadsList
                    otherlead={itm}
                    launchModalChangeState={launchModalChangeState}
                  ></OtherLeadsList>
                </tr>
              ))}
            </tbody>
          </table>
          <PaginationOtherLeads
            statePagination={statePagination}
            totalItems={otherLeads?.length}
            itemsPerPage={itemsPerPage}
            setStatePagination={setStatePagination}
          ></PaginationOtherLeads>
        </>
      ) : (
        <span>{t("noMoreLeads")}</span>
      )}
    </>
  );
};

export default FormLead;
