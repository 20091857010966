import React, { useState, useEffect, useContext, useCallback, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import moment from "moment";
import { Container, Col, Button } from "reactstrap";
import { FaFilter } from "react-icons/fa";
import { FiFilter } from "react-icons/fi";

import styles from "./index.module.scss";
import {
	DOCUMENTATION_ACTIONS,
	documentationFiltersInitialState,
	documentationFiltersReducer
} from "./actions/documentationReducer";

import { UserContext } from "@contexts/UserContext";
import MultiSelectChipsList from "@components/MultiSelectChipsList";
import Paginator from "@components/Paginator";
import Checkbox from "@components/Checkbox";
import Select from "@components/Select";
import DocumentationTable from "@components/DocumentationTable/DocumentationTable";
import GenericModal from "@components/GenericModal";
import { useDebouncedValue } from "@hooks/useDebouncedValue";
import { post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import errorCall from "@services/errorCall";
import { errorToast } from "@helpers/toastFunctions";
import { formatDateWithoutHour, parseDateToISODate } from "@helpers/formatDate";
import PaneHeader from "@components/PaneHeader";

const PAGE_SIZE = 15;

const PostventaContratos = () => {
	const { user, local } = useContext(UserContext);
	const { t } = useTranslation("myDocumentation");

	const [filterState, filterDispatch] = useReducer(
		documentationFiltersReducer,
		documentationFiltersInitialState
	);
	const freeFilterDebounce = useDebouncedValue(filterState.freeFilter, 500);

	const [documentsData, setDocumentsData] = useState({ count: 0, data: null });
	const [localFilterOptions, setLocalFilterOptions] = useState(null);

	const [filterList, setFilterList] = useState([]);
	const [isModalFiltersOpen, setIsModalFiltersOpen] = useState(false);

	const changeDocumentState = useCallback(async () => {
		if (!filterState.isResetting)
			try {
				setDocumentsData({ count: 0, data: null });

				const dataCall = {
					min: (filterState.page - 1) * PAGE_SIZE,
					limit: PAGE_SIZE,
					getLocales: local,
					checkFilters: filterState.selectedCheckbox,
					freeFilter: freeFilterDebounce,
					dateStart: filterState.startDate
						? moment(filterState.startDate).format("YYYY-MM-DD")
						: undefined,
					dateEnd: filterState.endDate
						? moment(filterState.endDate).format("YYYY-MM-DD")
						: undefined,
					onlyForLocal:
						filterState.dealershipSelect?.length > 0
							? filterState.dealershipSelect.map((l) => l.value)
							: false,
					user
				};

				if (filterState.documentTypeSelect.length > 0) {
					dataCall.documentos = filterState.documentTypeSelect.map((x) => x.value);
				}

				const response = await post(API.GET_DOCUMENTACION_FIRMAS_BY_LOCALES, dataCall);
				const documents = response.data.map((document) => {
					const {
						attacher,
						id_document_type,
						original_file_name,
						user_file_name,
						fecha_url,
						files,
						...rest
					} = document;

					if (document.documentNameCloud)
						return {
							files: [
								{
									attacher,
									id_document_type,
									user_file_name,
									fecha: fecha_url,
									sign_url_id: Number(document.documentNameCloud),
									url: document.url,
									id: document.documentId
								},
								...(files ?? [])
							],
							...rest
						};

					return document;
				});

				setDocumentsData({ data: documents, count: response.count });
			} catch (e) {
				errorCall(e);
			}
		else if (filterState.isResetting && freeFilterDebounce === "") {
			filterDispatch({ type: DOCUMENTATION_ACTIONS.COMPLETE_RESET });
		}
	}, [
		filterState.isResetting,
		filterState.documentTypeSelect,
		filterState.dealershipSelect,
		filterState.startDate,
		filterState.endDate,
		filterState.selectedCheckbox,
		freeFilterDebounce,
		filterState.page,
		local,
		user
	]);

	const handleDeletedDropdownSelected = (element) => {
		const updateFilter = (filter, element) => {
			return filter.filter((item) => item.label && item.label.toString() !== element);
		};

		const updatedLocales = updateFilter(filterState.dealershipSelect, element);
		const updatedTypes = updateFilter(filterState.documentTypeSelect, element);

		filterDispatch({ type: DOCUMENTATION_ACTIONS.SET_DEALERSHIP_SELECT, payload: updatedLocales });
		filterDispatch({ type: DOCUMENTATION_ACTIONS.SET_DOCUMENT_TYPE_SELECT, payload: updatedTypes });

		if (parseDateToISODate(element) === filterState.startDate) {
			filterDispatch({ type: DOCUMENTATION_ACTIONS.SET_START_DATE, payload: "" });
		}

		if (parseDateToISODate(element) === filterState.endDate) {
			filterDispatch({ type: DOCUMENTATION_ACTIONS.SET_END_DATE, payload: "" });
		}
	};

	useEffect(() => {
		document.title = "Flexicar | Contratos";
	}, []);

	useEffect(() => {
		(async () => {
			try {
				const res = await post("/api/get_user_local", { user });
				setLocalFilterOptions(res);
			} catch (err) {
				errorToast(err.response.data.message);
			}
		})();
	}, [user]);

	useEffect(() => {
		if (local) {
			changeDocumentState();
		}
	}, [local, changeDocumentState]);

	useEffect(() => {
		const _filterList = [filterState.documentTypeSelect, filterState.dealershipSelect].filter(
			(x) => x
		);

		const filterListFlat = _filterList.flatMap((list) => list.map((item) => item.label.toString()));

		const filterDateList = [filterState.startDate, filterState.endDate]
			.filter((x) => x)
			.map((x) => formatDateWithoutHour(x));

		setFilterList([...filterListFlat, ...filterDateList]);
	}, [
		filterState.documentTypeSelect,
		filterState.dealershipSelect,
		filterState.startDate,
		filterState.endDate
	]);

	const filters = (
		<div className={`pt-3 pb-3 ${styles.filtersContent}`}>
			<FaFilter
				size={20}
				color="#007bff"
			/>
			{t("filters")}
			<Select
				className="my-2"
				isMulti
				placeholder={t("document")}
				value={filterState.documentTypeSelect}
				options={[
					{ label: t("sellContract"), value: 1 },
					{ label: t("booking"), value: 2 },
					{ label: t("directPurchase"), value: 3 },
					{ label: t("salesManagement"), value: 4 }
				]}
				onChange={(values) =>
					filterDispatch({ type: DOCUMENTATION_ACTIONS.SET_DOCUMENT_TYPE_SELECT, payload: values })
				}
			/>
			{localFilterOptions ? (
				<Select
					isMulti
					placeholder={t("locals")}
					value={filterState.dealershipSelect}
					options={localFilterOptions}
					onChange={(values) =>
						filterDispatch({ type: DOCUMENTATION_ACTIONS.SET_DEALERSHIP_SELECT, payload: values })
					}
				/>
			) : null}
			<br />
			<div className="mb-3">
				<label>{t("from") + ":"}</label>
				<Form.Control
					type="date"
					name="dateStart"
					value={filterState.startDate}
					onChange={(e) =>
						filterDispatch({ type: DOCUMENTATION_ACTIONS.SET_START_DATE, payload: e.target.value })
					}
					className="mb-1"
				/>
				<label>{t("to") + ":"}</label>
				<Form.Control
					type="date"
					name="dateEnd"
					value={filterState.endDate}
					onChange={(e) =>
						filterDispatch({ type: DOCUMENTATION_ACTIONS.SET_END_DATE, payload: e.target.value })
					}
				/>
			</div>
			<Checkbox
				name={t("pendingTasks")}
				status={filterState.selectedCheckbox.notAllOk}
				onChange={() =>
					filterDispatch({ type: DOCUMENTATION_ACTIONS.SET_SELECTED_CHECKBOX, payload: "notAllOk" })
				}
			/>
			<Checkbox
				name={t("okContract")}
				status={filterState.selectedCheckbox.contract_ok}
				onChange={() =>
					filterDispatch({
						type: DOCUMENTATION_ACTIONS.SET_SELECTED_CHECKBOX,
						payload: "contract_ok"
					})
				}
			/>
			<Checkbox
				name={t("okBilling")}
				status={filterState.selectedCheckbox.bill_date_ok}
				onChange={() =>
					filterDispatch({
						type: DOCUMENTATION_ACTIONS.SET_SELECTED_CHECKBOX,
						payload: "bill_date_ok"
					})
				}
			/>
			<Checkbox
				name={t("okSent")}
				status={filterState.selectedCheckbox.delivery_date_ok}
				onChange={() =>
					filterDispatch({
						type: DOCUMENTATION_ACTIONS.SET_SELECTED_CHECKBOX,
						payload: "delivery_date_ok"
					})
				}
			/>
			<br />
			<Button
				outline
				color="danger"
				block
				onClick={() => filterDispatch({ type: DOCUMENTATION_ACTIONS.RESET_FILTERS })}
			>
				{t("delete")}
			</Button>
		</div>
	);

	return (
		<div>
			<Container
				fluid
				className="p-0"
			>
				<div className="mt-4 d-flex">
					<Col className={`col-md-2 col-xs-12 d-none d-xl-block`}>{filters}</Col>
					<Col className="col-md-12 col-xs-12 col-xl-10">
						<PaneHeader title={t("myDocument")} />
						<div className="d-flex flex-wrap mb-1 mt-1">
							<MultiSelectChipsList
								dropdownSelected={filterList}
								deleteDropdownSelected={handleDeletedDropdownSelected}
							/>
						</div>
						<div className="d-flex flex-wrap">
							<Col
								xs={12}
								md={6}
								className="d-flex justify-content-end p-0"
							>
								<input
									autoComplete="off"
									placeholder={t("search") + "..."}
									className="form-control ds-input mt-1"
									type="text"
									value={filterState.freeFilter}
									onChange={(e) =>
										filterDispatch({
											type: DOCUMENTATION_ACTIONS.SET_FREE_FILTER,
											payload: e.target.value
										})
									}
								/>
								<Button
									onClick={() => setIsModalFiltersOpen(true)}
									className="ml-2 d-xl-none d-block"
								>
									<FiFilter size={25} />
								</Button>
							</Col>
							<Col
								xs={12}
								md={6}
								className="mt-3 text-right"
							>
								<span className="text-success">{t("documents") + ":"} </span>
								<span className="text-primary">{documentsData.count}</span>
							</Col>
						</div>

						<DocumentationTable
							documentsData={documentsData}
							setDocumentsData={setDocumentsData}
							changeDocumentState={changeDocumentState}
						/>

						<Paginator
							className={styles.center}
							pageSize={PAGE_SIZE}
							totalCount={documentsData.count}
							currentPage={filterState.page}
							onPageChange={(page) =>
								filterDispatch({ type: DOCUMENTATION_ACTIONS.SET_PAGE, payload: page })
							}
						/>
					</Col>
				</div>
			</Container>

			<GenericModal
				isOpen={isModalFiltersOpen}
				setIsOpen={setIsModalFiltersOpen}
				headContent="Filtros"
				bodyContent={<div>{filters}</div>}
				footerContent={
					<div>
						<Button
							onClick={() => setIsModalFiltersOpen(false)}
							color="secondary"
						>
							Aceptar
						</Button>
					</div>
				}
				centered
				modalClassName="modal-lg"
			/>
		</div>
	);
};

export default PostventaContratos;
