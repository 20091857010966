import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import Locale from "@constants/Locale";

const isDebugEnable = process.env.REACT_APP_I18N_DEBUG === "true";
const DEFAULT_LOCALE = Locale.ES;

const domainLocaleMap = {
	localhost: Locale.ES,
	"flexicar.es": Locale.ES,
	"flexicar.pt": Locale.PT
};

const domainDetector = {
	// We use the name to refer to it later when we want to tell i18next when to use it.
	name: "domain",
	lookup() {
		let locale = DEFAULT_LOCALE;
		if (typeof window !== "undefined") {
			const domain = window.location.hostname.split(".").slice(0).slice(-2).join(".");
			locale = domainLocaleMap[domain];
		}
		return locale;
	}
};
const languageDetector = new LanguageDetector();
languageDetector.addDetector(domainDetector);

i18n
	.use(Backend)
	.use(languageDetector)
	.use(initReactI18next)
	.init({
		load: "currentOnly",
		fallbackLng: {
			default: false
		},
		debug: isDebugEnable,
		interpolation: {
			escapeValue: false
		},
		react: {
			useSuspense: false
		},
		detection: {
			order: ["domain"]
		}
	});

export default i18n;
